import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSearchInputStore} from "../../../store/useSearchInputStore";
import {useSearchModalStore} from "../../../store/useSearchModalStore";
import useSearchBrands from "../../../query/useSearchBrands";
import {useNavigate} from "react-router-dom";
import {brandUrl} from "../../../util/url";
import {logSearch} from "../../../util/analytics";
import BrandListItem from "./BrandListItem";

import './SearchBrandList.scss';


/**
 * Search brand list
 * @param hostname
 * @param userAgent
 * @returns {JSX.Element}
 * @constructor
 */
export default function SearchBrandList({hostname, userAgent}) {
    const { searchQuery, initSearchQuery } = useSearchInputStore();
    const { closeModal } = useSearchModalStore();
    const navigate = useNavigate();

    const { data } = useSearchBrands(searchQuery);
    const searchBrands = data && data.searchBrands || [];

    const handleOnClick = useCallback((search) => {
        initSearchQuery();
        logSearch(search);
        navigate(`${brandUrl(search)}?isSearch=true`);
        closeModal();
    }, [initSearchQuery, navigate, closeModal]);

    const shouldShowList = useMemo(() =>
        searchQuery.length > 0 && searchBrands.length > 0
    , [searchQuery.length, searchBrands.length]);

    if (!shouldShowList) return null;

    return (
        <div>
            <div className='SearchBrandList-title'>
                Brands
            </div>
            {searchBrands.map((brand) => (
                <BrandListItem
                    key={brand.id}
                    brand={brand}
                    searchQuery={searchQuery}
                    onClick={handleOnClick}
                    hostname={hostname}
                    userAgent={userAgent}
                />
            ))}
        </div>
    );
}

SearchBrandList.propTypes = {
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired
};
