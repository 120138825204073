import React, {useEffect, useState} from 'react';
import icLogoKakao from './ic_logo_kakao.svg';

import './KakaoLoginButton.css';

/**
 * KakaoLoginButton
 * @returns {JSX.Element}
 * @constructor
 */
export default function KakaoLoginButton() {
    const clientId = process.env.REACT_APP_KAKAO_KEY;
    const [redirectUri, setRedirectUri] = useState('');

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const hostname = window.location.hostname;

            if (hostname === 'fruitsfamily.com' || hostname === 'production.fruitsfamily.com' || hostname === 'www.fruitsfamily.com') {
                setRedirectUri('https://fruitsfamily.com/auth/kakao/complete');
            } else {
                setRedirectUri('https://staging.fruitsfamily.com/auth/kakao/complete');
            }
        }
    }, []);

    const kakaoLoginUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code`;

    const handleClick = () => {
        window.location.href = kakaoLoginUrl;
    };

    return <button onClick={handleClick} className='KakaoLoginButton'>
        <img src={icLogoKakao} alt='kakao-logo' />
        <div>카카오톡으로 시작하기</div>
    </button>
}
