import React from 'react';
import PropTypes from 'prop-types';
import FollowButton from "../../../component/followButton/FollowButton";
import MatchingText from "../../../component/matchingText/MatchingText";

/**
 * 브랜드 아이템
 */
const BrandListItem = ({brand, searchQuery, onClick, hostname, userAgent}) => (
    <div className='SearchBrandList-card' onClick={() => onClick(brand.name)}>
        <div className='SearchBrandList-card-content'>
            <div className='SearchBrandList-card-name'>
                <div className='name'>
                    <MatchingText fullText={brand.name} matchText={searchQuery} />
                </div>
                {brand.name_kr && (
                    <div className='sub-name'>
                        <MatchingText fullText={brand.name_kr} matchText={searchQuery} />
                    </div>
                )}
            </div>
            <div>
                <FollowButton hostname={hostname} userAgent={userAgent}/>
            </div>
        </div>
    </div>
);

BrandListItem.propTypes = {
    brand: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        name_kr: PropTypes.string
    }).isRequired,
    searchQuery: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    hostname: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired
};

BrandListItem.displayName = 'BrandListItem';

export default React.memo(BrandListItem);
