import {useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";

const SEARCH_BRANDS = gql`
    query SearchBrands($query: String!, $offset: Int!, $limit: Int!) {
        searchBrands(query: $query, offset: $offset, limit: $limit) {
            id
            name
            name_kr
        }
    }
`;

/**
 * Fetch search Brands.
 * @param searchQuery the search query
 * @return {*} the list of brand
 */
export default function useSearchBrands(searchQuery) {
    const {data, loading, error} = useQuery(SEARCH_BRANDS, {
        variables: {
            query: searchQuery || '',
            offset: 0,
            limit: 20
        },
        skip: !searchQuery,
        fetchPolicy: 'network-only'
    });

    return searchQuery ? {
        data,
        loading,
        error,
    } : {};
}
